<template>
  <div>
    <div class="card-body">

      <div v-if="!isAvalibleStatus" class="alert alert-warning mb-0">
        <i class="fas fa-exclamation-triangle"></i> ไม่สามารถออกผลได้ ({{roundStatus}})
      </div>
      <div v-else-if="!isRoundClosed" class="alert alert-warning mb-0">
        <i class="fas fa-exclamation-triangle mr-2"></i>
        <RoundStatus v-if="round" :data="round" @cssStyle="setStatusStyle" class="badge badge-status font-weight-normal" :class="[cssStatusClass.bg, cssStatusClass.text]" style="font-size: 100%;" />
        ยังไม่ถึงเวลาออกผล
      </div>

      <div v-else>
        <div v-if="isPaid" class="alert alert-success mb-4">
          <i class="far fa-check-circle"></i> จ่ายเงินแล้ว
        </div>

        <div class="row mb-3">
          <div class="col-sm-3 d-flex align-items-center">
            <label class="text-success font-weight-bold mb-0">ผล 4 ตัว</label>
          </div>
          <div class="col-sm-9 form-inline">
            <span v-if="isPaid" class="badge badge-success mr-1">{{fourNumber}}</span>
            <b-form-input
              v-else
              placeholder="4 ตัวตรง"
              v-model="fourNumber"
              v-on:keydown="keypress"
              maxlength="4">
            </b-form-input>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-sm-3 d-flex align-items-center">
            <label class="text-success font-weight-bold mb-0">3 ตัวตรง</label>
          </div>
          <div class="col-sm-9 text-primary">
            <span v-for="number in threeNumber" class="badge badge-success mr-1 mb-1">{{number}}</span>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-sm-3 d-flex align-items-center">
            <label class="text-success font-weight-bold mb-0">4 ตัวโต๊ด</label>
          </div>
          <div class="col-sm-9 text-primary">
            <span v-for="number in fourNumberTode" class="badge badge-success mr-1 mb-1">{{number}}</span>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-sm-3 d-flex align-items-center">
            <label class="text-success font-weight-bold mb-0">3 ตัวโต๊ด</label>
          </div>
          <div class="col-sm-9 text-primary">
            <span v-for="number in threeNumberTode" class="badge badge-success mr-1 mb-1">{{number}}</span>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-sm-3 d-flex align-items-center">
            <label class="text-success font-weight-bold mb-0">2 ตัวหน้า</label>
          </div>
          <div class="col-sm-9 text-primary">
            <span v-for="number in twoNumberPrefix" class="badge badge-success mr-1 mb-1">{{number}}</span>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-sm-3 d-flex align-items-center">
            <label class="text-success font-weight-bold mb-0">2 ตัวหลัง</label>
          </div>
          <div class="col-sm-9 text-primary">
            <span v-for="number in twoNumberSuffix" class="badge badge-success mr-1 mb-1">{{number}}</span>
          </div>
        </div>

      </div>
    </div>
    <div v-if="isAvalibleStatus && isRoundClosed && !isPaid" class="card-footer text-right">
      <b-button variant="primary" @click="saveResults"><i class="far fa-save"></i> บันทึกผล</b-button>
    </div>
  </div>
</template>
<script>
import { marketOpenBets } from "@/configs/market.config"
import LottoService from '@/services/lottoService'
import moment from '@/helpers/moment'
import Swal from 'sweetalert2'
import RoundStatus from './RoundStatus'

export default {
  name: 'ResultLottoset',
  props: ['round'],
  components: {
    RoundStatus
  },
  data() {
    return {
      cssStatusClass: {
        bg: null,
        text: null,
        link: null,
        btn: null
      },
      fourNumber: this.round.results.fourNumber.join('')
    }
  },
  computed: {
    marketOpenBets() {
      return marketOpenBets
    },
    roundId() {
      return this.$store.state.globalMarket.round.roundId
    },
    roundStatus() {
      return this.round?.status
    },
    isAvalibleStatus() {
      if(!this.round)
        return false

      return ['Open', 'Close', 'Complete', 'Paid'].includes(this.round.status)
    },
    isRoundClosed() {
      if(!this.round)
        return false

      if(moment().isBefore(this.round.roundDate.close))
        return false

      return true
    },
    isPaid() {
      return this.round?.status === 'Paid'
    },
    awardFour() {
      if(!this.fourNumber)
        return null

      const awards = this.fourNumber.slice('')
      if(awards.length !== 4)
        return null

      return awards
    },
    threeNumber() {
      if(!this.awardFour)
        return []

      return [`${this.awardFour[1]}${this.awardFour[2]}${this.awardFour[3]}`]
    },
    fourNumberTode() {
      if(!this.awardFour)
        return []

      return [
        `${this.awardFour[0]}${this.awardFour[1]}${this.awardFour[2]}${this.awardFour[3]}`,
        `${this.awardFour[0]}${this.awardFour[1]}${this.awardFour[3]}${this.awardFour[2]}`,
        `${this.awardFour[0]}${this.awardFour[2]}${this.awardFour[1]}${this.awardFour[3]}`,
        `${this.awardFour[0]}${this.awardFour[2]}${this.awardFour[3]}${this.awardFour[1]}`,
        `${this.awardFour[0]}${this.awardFour[3]}${this.awardFour[2]}${this.awardFour[1]}`,
        `${this.awardFour[0]}${this.awardFour[3]}${this.awardFour[1]}${this.awardFour[2]}`,
        `${this.awardFour[1]}${this.awardFour[0]}${this.awardFour[2]}${this.awardFour[3]}`,
        `${this.awardFour[1]}${this.awardFour[0]}${this.awardFour[3]}${this.awardFour[2]}`,
        `${this.awardFour[1]}${this.awardFour[2]}${this.awardFour[0]}${this.awardFour[3]}`,
        `${this.awardFour[1]}${this.awardFour[2]}${this.awardFour[3]}${this.awardFour[0]}`,
        `${this.awardFour[1]}${this.awardFour[3]}${this.awardFour[2]}${this.awardFour[0]}`,
        `${this.awardFour[1]}${this.awardFour[3]}${this.awardFour[0]}${this.awardFour[2]}`,
        `${this.awardFour[2]}${this.awardFour[0]}${this.awardFour[1]}${this.awardFour[3]}`,
        `${this.awardFour[2]}${this.awardFour[0]}${this.awardFour[3]}${this.awardFour[1]}`,
        `${this.awardFour[2]}${this.awardFour[1]}${this.awardFour[0]}${this.awardFour[3]}`,
        `${this.awardFour[2]}${this.awardFour[1]}${this.awardFour[3]}${this.awardFour[0]}`,
        `${this.awardFour[2]}${this.awardFour[3]}${this.awardFour[1]}${this.awardFour[0]}`,
        `${this.awardFour[2]}${this.awardFour[3]}${this.awardFour[0]}${this.awardFour[1]}`,
        `${this.awardFour[3]}${this.awardFour[0]}${this.awardFour[1]}${this.awardFour[2]}`,
        `${this.awardFour[3]}${this.awardFour[0]}${this.awardFour[2]}${this.awardFour[1]}`,
        `${this.awardFour[3]}${this.awardFour[1]}${this.awardFour[0]}${this.awardFour[2]}`,
        `${this.awardFour[3]}${this.awardFour[1]}${this.awardFour[2]}${this.awardFour[0]}`,
        `${this.awardFour[3]}${this.awardFour[2]}${this.awardFour[1]}${this.awardFour[0]}`,
        `${this.awardFour[3]}${this.awardFour[2]}${this.awardFour[0]}${this.awardFour[1]}`
      ]
    },
    threeNumberTode() {
      if(!this.awardFour)
        return []

      return [
        `${this.awardFour[1]}${this.awardFour[2]}${this.awardFour[3]}`,
        `${this.awardFour[1]}${this.awardFour[3]}${this.awardFour[2]}`,
        `${this.awardFour[2]}${this.awardFour[1]}${this.awardFour[3]}`,
        `${this.awardFour[2]}${this.awardFour[3]}${this.awardFour[1]}`,
        `${this.awardFour[3]}${this.awardFour[2]}${this.awardFour[1]}`,
        `${this.awardFour[3]}${this.awardFour[1]}${this.awardFour[2]}`,
      ]
    },
    twoNumberPrefix() {
      if(!this.awardFour)
        return []

      return [`${this.awardFour[0]}${this.awardFour[1]}`]
    },
    twoNumberSuffix() {
      if(!this.awardFour)
        return []

      return [`${this.awardFour[2]}${this.awardFour[3]}`]
    }
  },
  watch: {
    round() {
      if(this.round) {
        this.award = {
          three: this.round.results.threeNumberTop.join(''),
          two: this.round.results.twoNumberBottom.join('')
        }
      }
    }
  },
  methods: {
    setStatusStyle(css) {
      this.cssStatusClass = {
        ...this.cssStatusClass,
        ...css
      }
    },
    keypress(event) {
      if(!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace'].includes(event.key)) {
        event.preventDefault()
      }
    },
    saveResults() {

      if(!this.fourNumber)
        return Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'กรุณากรอกผล'
        })

      // const results = {
      //   fourNumber: [this.fourNumber],
      //   threeNumber: this.threeNumber,
      //   fourNumberTode: this.fourNumberTode,
      //   threeNumberTode: this.threeNumberTode,
      //   twoNumberPrefix: this.twoNumberPrefix,
      //   twoNumberSuffix: this.twoNumberSuffix
      // }
      const results = {
        fourNumber: this.fourNumber
      }

      Swal.fire({
        title: 'ยืนยันบันทึกผล?',
        text: "กรุณาตรวจสอบผลให้ถูกต้องก่อนกดยืนยัน",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return LottoService.saveLottosetResults(this.round._id, results).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.fire(
              'ผิดพลาด!',
              (error.message||'บันทึกผลหวยไม่สำเร็จ'),
              'error'
            )
          })
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            'บันทึกผลหวยสำเร็จ',
            'success'
          )
          .then(()=>{
            this.$router.push({name: 'PayWinTickets'})
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.badge {
  font-size: 100%;
  font-weight: normal;
  letter-spacing: 1px;
}
</style>
